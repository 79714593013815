<template>
  <div>
    <div>
      <img class="comimg" style="width: 100%" src="@/assets/busniss/banner.png"/>
    </div>
    <div class="res" style="height: 889px">
      <img class="comimg" style="width: 100%" src="@/assets/busniss/bg.png"/>
      <div class="abs home-p-com">
        <div class="com-page-t1">合作优势</div>
        <div style="margin-top: 84px;padding: 0 15px;display: flex;justify-content: space-between; flex-wrap: wrap;">
          <div v-for="(item,k) in ysArr" :key="k" class="advantage res">
            <img class="advantage-dot" src="@/assets/we/点缀.png">
            <img style="width: 180px;height: 180px;margin-left: 40px;margin-right: 30px" :src="item.icon">
            <div style="text-align: left">
              <div class="ys-tit">{{item.tit}}</div>
              <p class="ys-desc">{{item.desc}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="res" style="height: 735px">
      <img class="comimg" style="width: 100%" src="@/assets/busniss/bg1.png"/>
      <div class="abs home-p-com">
        <div class="com-page-t1">合作要求</div>
        <div style="margin-top: 75px;padding: 0 15px;display: flex;justify-content: center;">
          <div v-for="(item,k) in requestArr" :key="k" class="request res">
            <div class="abs re-index">0{{k+1}}</div>
            <div class="re-tit">{{item.tit}}</div>
            <p class="re-desc">{{item.desc}}</p>
            <img class="abs re-img" :src="item.icon">
          </div>
        </div>
      </div>
    </div>
    <div class="res" style="height: 643px">
      <img class="comimg" style="width: 100%" src="@/assets/busniss/bg2.png"/>
      <div class="abs home-p-com">
        <div class="com-page-t1">加入流程</div>
        <div style="margin-top: 75px;padding: 0 15px;display: flex;justify-content: space-between;">
          <div v-for="(item,k) in flowPath" :key="k" class="joinlc res">
            <img v-if="item.tit" style="width: 163px;height: 137px" :src="item.icon">
            <img v-else style="width: 146px;height: 38px;margin-top: 51px" :src="item.icon">
            <div v-if="item.tit" class="join-tit">{{item.tit}}</div>
            <div v-if="item.desc" class="join-desc">{{item.desc}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="res" style="height: 306px">
      <img class="comimg" style="width: 100%" src="@/assets/busniss/bg3.png"/>
      <div class="abs home-p-com cflex" style="flex-direction: column;color: white">
        <div style="text-align: center;font-weight: 600;font-size: 40px;margin-bottom: 10px">提交联系方式，我们会第一时间联系您</div>
        <el-form
            ref="weForm1"
            :rules="rules"
            :model="ruleForm"
            label-width="0px"
        >
          <div class="uploadMsg">
            <img class="upload-img" src="@/assets/we/ofs-i-gongsi.png">
            <el-form-item label="" prop="companyName">
              <el-input
                  style="width: 245px;margin-top: 20px"
                  v-model="ruleForm.companyName"
                  placeholder="公司名称"
              ></el-input>
            </el-form-item>
            <img class="upload-img" src="@/assets/we/ofs-icon_login-zhangxiaodelu.png">
            <el-form-item label="" prop="contactorName">
              <el-input
                  v-model="ruleForm.contactorName"
                  style="width: 245px;margin-top: 20px"
                  placeholder="姓名"
              ></el-input>
            </el-form-item>
            <img class="upload-img" src="@/assets/we/ofs-i-phone.png">
            <el-form-item label="" prop="contactorPhone">
              <el-input
                  style="width: 245px;margin-top: 20px"
                  v-model="ruleForm.contactorPhone"
                  placeholder="电话"
              ></el-input>
            </el-form-item>
            <div class="e-btn"
                 style="background: linear-gradient(180deg, #4189FF 0%, #034BFF 100%);color: white;margin-left: 12px"
                 @click="save">免费试用
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "business",
  data () {
    var validateMobile = (rule, value, callback) => {
      let reg = /^1[3-9]\d{9}$/;
      if (value == "" || !value) {
        callback(new Error("请输入电话"));
      } else {
        if (!reg.test(value)) {
          callback(new Error("请检查手机格式"));
        } else {
          callback();
        }
      }
    };
    return {
      ysArr: [
        {
          icon: require('@/assets/busniss/ofs-i-zizhi.png'),
          tit: '专业资质',
          desc: '自主研发设计\n拥有自主知识产权'
        },
        {
          icon: require('@/assets/busniss/ofs-i-lianhe.png'),
          tit: '强强联合',
          desc: '企修通与同行、跨行合作\n优势资源互补'
        },
        {
          icon: require('@/assets/busniss/ofs-i-zhichi.png'),
          tit: '强大支持',
          desc: '专业技术、服务团队，全面培\n训，一对一售前支持多套解决\n方案，全套资源整合'
        },
        {
          icon: require('@/assets/busniss/ofs-i-funeng.png'),
          tit: '产业赋能',
          desc: '助力打造高效化\n数字化产业'
        }
      ],
      requestArr: [
        {
          icon: require('@/assets/busniss/A.png'),
          tit: '资格',
          desc: '具有独立的法人资格，有良好的商业信誉'
        },
        {
          icon: require('@/assets/busniss/B.png'),
          tit: '资源',
          desc: '所在区域以及行业有广泛的市场基础，拥有用户资源和品牌号召力'
        },
        {
          icon: require('@/assets/busniss/C.png'),
          tit: '能力',
          desc: '有专职销售人员和技术人员，能为用户提供符合企修通服务体系的服务能力'
        },
      ],
      flowPath: [
        {
          icon: require('@/assets/busniss/lc1.png'),
          tit: '申请加入',
          desc: '在下方留下您的联系方式我们将会在收到您提交信息的第一时间联系您'
        },
        {
          icon: require('@/assets/busniss/line.png'),
        },
        {
          icon: require('@/assets/busniss/lc2.png'),
          tit: '合作沟通',
          desc: '您可以拨打免费热线400-8619-3648资讯合作事项'
        },
        {
          icon: require('@/assets/busniss/line.png'),
        },
        {
          icon: require('@/assets/busniss/lc3.png'),
          tit: '签约授权',
          desc: '携手企修通之后，将在业务支持、技术支持和商机挖掘等方面获得更多机会'
        },
        {
          icon: require('@/assets/busniss/line.png'),
        },
        {
          icon: require('@/assets/busniss/lc4.png'),
          tit: '相伴成长',
          desc: '企修通携手伙伴\n' +
            '共同成长\n' +
            '共同进步'
        },
      ],
      loading: false,
      ruleForm: {},
      rules: {
        companyName: [
          {required: true, message: "请输入公司名称", trigger: "blur"},
        ],
        contactorName: [
          {required: true, message: "请输入姓名", trigger: "blur"},
        ],
        contactorPhone: [{validator: validateMobile, trigger: "blur"}],
      },
    }
  },
  methods: {
    save () {
      let that = this;
      that.loading = true;
      this.$refs.weForm1.validate((valid) => {
        if (valid) {
          that.loading = true;
          this.$api.saveMessage(that.ruleForm).then((res) => {
            if (res.code == 200) {
              this.$message({
                message: "您的试用申请已提交，请耐心等待工作人员联系",
                type: "success",
              });
              that.loading = false;
            } else if (res.code == 500 && res.msg == "您已提交过此申请") {
              this.$message({
                message: res.msg,
                type: "warning",
              });
              that.loading = false;
            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
        } else {
          that.loading = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
  }
}
</script>

<style scoped>
  .advantage {
    width: 555px;
    height: 252px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background: linear-gradient(180deg, #FFFFFF 0%, #ECF3FF 100%);
    box-shadow: 0px 3px 10px 1px rgba(31, 95, 255, 0.1);
    border-radius: 18px 18px 18px 18px;
    border: 2px solid #FFFFFF;
    margin-bottom: 60px;
  }

  .advantage:hover {
    background: url("../../assets/busniss/bghzys.png") no-repeat;
    background-size: 100% 100%;
  }

  .advantage:hover .advantage-dot {
    opacity: 0;
  }

  .advantage:hover .ys-tit {
    color: white;
  }

  .advantage:hover .ys-desc {
    color: white;
  }

  .advantage-dot {
    width: 32px;
    height: 36px;
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .ys-tit {
    font-size: 28px;
    font-weight: 600;
    color: #1F5FFF;
    line-height: 32px;
  }

  .ys-desc {
    margin-top: 17px;
    font-size: 18px;
    font-weight: 400;
    color: #35363F;
    white-space: pre-line;
  }

  .uploadMsg {
    width: 1015px;
    height: 72px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    align-items: center;
  }

  .upload-img {
    width: 32px;
    height: 32px;
    margin: 0 0 0px 10px;
  }

  .request {
    width: 361px;
    height: 411px;
    background: url("../../assets/busniss/bgyq.png") no-repeat;
    background-size: 100% 100%;
    margin: 0 30px;
  }

  .request:hover {
    background: url("../../assets/busniss/bgsyq.png") no-repeat;
    background-size: 100% 100%;
  }

  .request:hover .re-tit {
    color: white;
  }

  .request:hover .re-desc {
    color: white;
  }

  .re-index {
    left: 31px;
    top: 60px;
    font-size: 68px;
    font-weight: bold;
    color: rgba(31, 95, 255, 0.2);
    line-height: 32px;
    width: 90px;
    height: 80px;
  }

  .re-tit {
    margin: 76px 30px 20px 31px;
    font-size: 32px;
    font-weight: 600;
    color: #171717;
    line-height: 36px;
  }

  .re-desc {
    margin: 0 40px 0 31px;
    font-size: 22px;
    font-weight: 400;
    color: #35363F;
    line-height: 32px;
  }

  .re-img {
    bottom: 21px;
    right: 21px;
    width: 130px;
    height: 120px;
  }

  .joinlc {
    display: flex;
    width: 226px;
    flex-direction: column;
    align-items: center;
  }

  .joinlc:hover .join-tit {
    color: #1f5fff;
  }

  .joinlc:hover .join-desc {
    color: #171717;
  }

  .join-tit {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 600;
    color: #171717;
  }

  .join-desc {
    font-size: 18px;
    font-weight: 400;
    color: #606266;
    line-height: 28px;
    text-align: center;
  }
</style>
